<template>
  <!-- 机票列表 返程 -->
  <SearchUI :query="query" @updateDate="updateDate" @chooseTicket="chooseTicket" :startDate="startDate"
    :secondKey="true" :routename="'searchAircraftBack'">
    <!-- 顶部插槽 -->
    <template v-slot:header>
      <h3 class="bigTitle green">
        <span>
          选返程：{{aircraftOrderInfo.to}}
          <van-icon class="iconfont animate__animated" class-prefix="icon" name="tuijianjipiao" />
          {{aircraftOrderInfo.from}}
        </span>
      </h3>
    </template>
  </SearchUI>
</template>

<script>
import { mapGetters } from "vuex";
import { AddDate } from "@/services/date";
import SearchUI from "./components/SearchAircraft";
export default {
  components: { SearchUI },
  computed: {
    ...mapGetters(["aircraftOrderInfo"]),
    // 丢给子组件的查询参数
    query: function() {
      let obj = {
        depCityCode: this.aircraftOrderInfo.toCode,
        arrCityCode: this.aircraftOrderInfo.fromCode,
        depDate: this.aircraftOrderInfo.date[1],
        corp: null
      };
      return obj;
    }
  },
  data() {
    return {
      startDate: null // 日历开始时间
    };
  },
  activated() {
    this.startDate = AddDate(this.aircraftOrderInfo.date[0]);
  },
  methods: {
    // 更新aircraftOrderInfo的时间，把整个aircraftOrderInfo结构都传过去
    updateDate(time) {
      this.$set(this.aircraftOrderInfo.date, 1, time);
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      );
    },
    // 选票（比价在子组件完成了）
    chooseTicket(tickets, query) {
      // 把比价结果放进vuex
      this.$store.dispatch("aircraft/setAircraftTicket", {
        data: tickets,
        index: 1
      });
      let newQuery = {
        corp0: this.$route.query.corp,
        corp1: query.corp
      };
      this.$router.push({
        path: "/orderReturnAircraft",
        query: newQuery
      });
    }
  }
};
</script>

<style scoped>
.bigTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.4rem;
  background: #e6ffdf;
  text-align: center;
}
</style>

